import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';

// Components
import FormField from 'layouts/components/FormField';
import FormSelect from 'layouts/components/FormSelect';

// Utils
import { defaultValue, findSelectValue, getDateFormat } from 'Util';

function DefaultInterviewCard(
  {
    i,
    e,
    setValue,
    removeFunc,
  },
) {
  const { t } = useTranslation('translation', { keyPrefix: 'candidates.interviews' });

  const [expanded, setExpanded] = useState(true);
  const [interviewMenu, setInterviewMenu] = useState(null);

  const openInterviewMenu = (event) => setInterviewMenu(event.currentTarget);
  const closeInterviewMenu = () => setInterviewMenu(null);

  const handleExpand = () => {
    closeInterviewMenu();
    setExpanded(!expanded);
  };

  const offerStatuses = [
    {
      value: 1,
      label: 'Pending',
    },
    {
      value: 2,
      label: 'Rejected',
    },
    {
      value: 3,
      label: 'Accepted',
    },
  ];

  return (
    <Card sx={{ overflow: 'visible' }}>
      <CardHeader
        action={(
          <IconButton onClick={openInterviewMenu}>
            <MoreVertIcon />
          </IconButton>
        )}
        title={`${e.position_listing.customer_name} - (${e.position_listing.name})`}
      />
      <Menu
        anchorEl={interviewMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(interviewMenu)}
        onClose={closeInterviewMenu}
        keepMounted
      >
        <MenuItem
          onClick={handleExpand}
        >
          {expanded
            ? t('collapse', { keyPrefix: 'common' })
            : t('expand', { keyPrefix: 'common' })}
        </MenuItem>
        <MenuItem
          onClick={() => {
            removeFunc(i);
          }}
        >
          {t('remove', { keyPrefix: 'common' })}
        </MenuItem>
      </Menu>
      <SuiBox p={1}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <SuiBox mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} pr={1}>
                <FormField
                  label={t('preparation-email-sent-on')}
                  type="date"
                  error={e.preparation_email_sent_on ? false
                    : !e.preparation_email_sent_on || e.preparation_email_sent_on === ''}
                  onChange={(event) => {
                    setValue('preparation_email_sent_on', event.target.value, i);
                  }}
                  value={getDateFormat(Date.parse(defaultValue(e.preparation_email_sent_on)))}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                <FormField
                  label={t('preparation-interview-schedule')}
                  type="date"
                  error={e.preparation_interview_schedule ? false
                    : !e.preparation_interview_schedule || e.preparation_interview_schedule === ''}
                  onChange={(event) => {
                    setValue('preparation_interview_schedule', event.target.value, i);
                  }}
                  value={getDateFormat(Date.parse(defaultValue(e.preparation_interview_schedule)))}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                <FormField
                  label={t('interview-schedule')}
                  type="date"
                  error={e.interview_schedule ? false
                    : !e.interview_schedule || e.interview_schedule === ''}
                  onChange={(event) => {
                    setValue('interview_schedule', event.target.value, i);
                  }}
                  value={getDateFormat(Date.parse(defaultValue(e.interview_schedule)))}
                />
              </Grid>
              <Grid item xs={12} md={3} pr={1}>
                <FormSelect
                  label={t('offer-status')}
                  placeholder={t('offer-status')}
                  options={offerStatuses}
                  id={`states[${i}]`}
                  value={
                    findSelectValue(offerStatuses, e.offer_status, i)
                  }
                  error={e.offer_status ? false
                    : !e.offer_status || e.offer_status === 0}
                  onChange={(event) => {
                    setValue('offer_status', event.value, i);
                  }}
                />
              </Grid>
            </Grid>
          </SuiBox>
        </Collapse>
      </SuiBox>
    </Card>
  );
}

DefaultInterviewCard.propTypes = {
  i: PropTypes.number.isRequired,
  e: PropTypes.shape(
    {
      position_listing: PropTypes.shape(
        {
          name: PropTypes.string,
          customer_name: PropTypes.string,
        },
      ),
      preparation_email_sent_on: PropTypes.string,
      preparation_interview_schedule: PropTypes.string,
      interview_schedule: PropTypes.string,
      offer_status: PropTypes.number,
      id: PropTypes.number,
    },
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
};

export default DefaultInterviewCard;
