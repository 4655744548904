import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Reducer functions
import { fetchPositionListings } from 'reducers/positionListingsSlice';
import {
  createCandidateInterview,
  deleteCandidateInterview,
  fetchCandidateInterviews,
  fetchCandidateProfile,
  setInterviews,
  setRemoveData,
  updateCandidateInterview,
} from 'reducers/candidatesSlice';

// @mui material components
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

// Soft UI Dashboard PRO React components
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';

// Components
import FormSelect from 'layouts/components/FormSelect';
import DefaultInterviewCard from 'layouts/components/Cards/InterviewCards/DefaultInterviewCard';
// eslint-disable-next-line
import {
  getCandidateUUID,
  MissingDataWarning,
  NoChangesWarning,
  prepareModifyData,
  prepareRemoveData,
  setTitle,
} from 'Util';

const selector = (state) => ({
  editing: state.candidate.editing,
  positionListings: state.positionListing.positionListings,
  candidateProfile: state.candidate.profile,
  interviews: state.candidate.interviews,
  removeData: state.candidate.removeData,
});

function Interviews() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'candidates.interviews',
  });

  const {
    editing,
    positionListings,
    candidateProfile,
    interviews,
    removeData,
  } = useSelector(selector, shallowEqual);
  const dispatch = useDispatch();
  const uuid = getCandidateUUID();

  useEffect(() => {
    dispatch(fetchPositionListings());

    if (editing && uuid !== undefined) {
      dispatch(fetchCandidateProfile({ uuid }));
      dispatch(fetchCandidateInterviews({ uuid }));
    }
  }, [dispatch, editing, uuid]);

  setTitle('Candidate Interiews');

  const [availablePosition, setAvailablePosition] = useState(0);
  const [availablePositions, setAvailablePositions] = useState([]);

  useEffect(() => {
    setAvailablePositions(
      positionListings.filter(
        (p) => !interviews.some((i) => p.id === i.position_listing.id),
      ),
    );
    // eslint-disable-next-line
  }, [positionListings, interviews]);

  const handleAssign = () => {
    if (availablePosition > 0) {
      const assigned = positionListings.find(
        (p) => p.position_listing_id === availablePosition,
      );
      if (assigned === undefined) {
        dispatch(
          createCandidateInterview({
            positionListingId: availablePosition,
            candidateId: candidateProfile.uuid,
          }),
        );
      }
    }
  };

  const handleRemove = (i) => {
    if (interviews[i].id !== undefined) {
      dispatch(
        setRemoveData({
          ...removeData,
          interviews: removeData.interviews.concat(interviews[i].id),
        }),
      );
    }

    dispatch(
      setInterviews([...interviews.slice(0, i), ...interviews.slice(i + 1)]),
    );
  };

  const setValue = (key, value, i) => {
    dispatch(
      setInterviews(
        interviews.map((obj, index) => {
          if (index === i) {
            return {
              ...obj,
              [key]: value,
              changed: true,
            };
          }
          return obj;
        }),
      ),
    );
  };

  const handleSave = () => {
    const invalidInterviews = interviews.filter((i) => i.valid === false);

    let isValid = true;

    if (invalidInterviews.length > 0) {
      isValid = false;
    }

    if (isValid) {
      const evals = interviews.filter((item) => item.changed === true);
      if (evals.length > 0 || removeData.interviews.length > 0) {
        prepareModifyData(interviews).map((e) => {
          const data = {
            candidateId: candidateProfile.uuid,
            preparationEmailSentOn: e.preparation_email_sent_on,
            preparationInterviewSchedule: e.preparation_interview_schedule,
            interviewSchedule: e.interview_schedule,
            offerStatus: e.offer_status,
          };
          if (typeof e.id !== 'undefined') {
            data.id = e.id;
            dispatch(updateCandidateInterview(data));
          } else {
            dispatch(createCandidateInterview(data));
          }

          return true;
        });

        prepareRemoveData(removeData.nursingBoards).map((c) => {
          dispatch(
            deleteCandidateInterview({
              candidateId: candidateProfile.uuid,
              id: c,
            }),
          );

          dispatch(
            setRemoveData({
              ...removeData,
              interviews: removeData.interviews.filter((i) => i !== c),
            }),
          );

          return true;
        });
      } else {
        NoChangesWarning(t);
      }
    } else {
      MissingDataWarning(t);
    }
  };

  return (
    <>
      <SuiBox>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={8} md={8}>
            <FormSelect
              label={t('positions-available')}
              options={availablePositions}
              onChange={(e) => {
                setAvailablePosition(e.value);
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} mt={3}>
            <SuiBox display="flex" flexDirection="row">
              <SuiButton variant="gradient" size="small" onClick={handleAssign}>
                {t('assign')}
              </SuiButton>
            </SuiBox>
          </Grid>
        </Grid>
        <Divider />
        {Object.keys(interviews).length > 0
          && interviews.map((p, i) => (
            <div
              key={`interview${i}`}
            >
              <DefaultInterviewCard
                i={i}
                e={p}
                setValue={setValue}
                removeFunc={handleRemove}
              />
              <Divider />
            </div>
          ))}
      </SuiBox>
      <SuiBox mt={5} mb={3}>
        <SuiBox display="flex" justifyContent="flex-end" mt={6}>
          <SuiBox mr={1}>
            <SuiButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleSave}
            >
              {t('save', { keyPrefix: 'common' })}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </>
  );
}

export default Interviews;
